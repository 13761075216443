import {Injectable, OnDestroy} from '@angular/core';
import {
  BelegDTO,
  BelegService,
  FilterBelegDTO,
  InhaberDTO,
  PageableBelegDTOSortingEnum
} from '../../../openapi/beleg-openapi';
import {catchError, debounceTime, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subject, take, tap} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {UtilityWidgetData} from './utility-widget-data';
import {WidgetHeaderData} from '../widget-header/widget-header-data';
import {WidgetInhaberData} from '../widget-header/widget-inhaber-data';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {InhaberEntitiesSelectors, NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root',
})
export class UtilityWidgetService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private utilityWidgetData$ = new BehaviorSubject<UtilityWidgetData[] | undefined>(undefined);

  private widgetHeaderData$ = new BehaviorSubject<WidgetHeaderData | undefined>(undefined);

  private currentInhabers$ = this.store.select(InhaberEntitiesSelectors.fullInhaberList).pipe(
    takeUntil(this.unsubscribe$),
    tap(inhaberList => {
      for (const inhaber of inhaberList) {
        this.readUtilityWidgetData(inhaber.id);
      }
    }),
  );

  constructor(
    private logger: NGXLogger,
    private navigationService: NavigationService,
    private belegService: BelegService,
    private store: Store<AppState>,
  ) {

    this.currentInhabers$.subscribe();

    // INFO: Aktualisiert das WidgetHeaderData mit den neuen Informationen aus dem UtilityWidgetData.
    this.utilityWidgetData$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(100),
    ).subscribe(utilityWidgetDataList => {
      const widgetHeaderData: WidgetHeaderData = {
        title: 'Belege Online',
      };

      if (utilityWidgetDataList) {
        for (const utilityWidgetData of utilityWidgetDataList) {
          this.updateUtilityWidgetHeaderData(widgetHeaderData, utilityWidgetData);
        }
      }

      this.widgetHeaderData$?.next(widgetHeaderData);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get utilityWidgetData(): Observable<UtilityWidgetData[] | undefined> {
    return this.utilityWidgetData$.asObservable();
  }

  get utilityWidgetHeaderData(): BehaviorSubject<WidgetHeaderData | undefined> {
    return this.widgetHeaderData$;
  }

  /**
   * Lesen der Daten für das UtilityWidgetData vom Backend.
   *
   * @param inhaberId Die ID des Inhabers, für den die Daten geladen werden sollen
   * @param pageIndex Die Seite für die Pagination
   */
  public readUtilityWidgetData(inhaberId: string, pageIndex: number = 0) {

    if (pageIndex == undefined) {
      this.logger.warn('pageIndex is', pageIndex, '. Use default value "0".');
      pageIndex = 0;
    }

    const filter: FilterBelegDTO = {
      offen: true,
      bearbeitet: true
    };

    // TODO: Bei Bedarf könnte man die pageSize berechnen. Mat-Table height / Tr height (48px)
    const pageSize = 16;
    const pagaeble = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      sorting: [PageableBelegDTOSortingEnum.Erstelltdesc],
    };

    // INFO: Erst das Laden der Belege
    this.belegService.readBelege(inhaberId, filter, pagaeble).pipe(
      take(1),
      catchError(error => {
        this.logger.warn('could not read belege for utility-widget', error);
        return of(undefined);
      }),
    ).subscribe(belege => {

      if (!belege) {
        this.logger.warn('belege is', belege, '. Use default value "[]".');
        belege = [];
      }

      if (belege.length === 0 && pageIndex > 0) {
        this.readUtilityWidgetData(inhaberId, pageIndex - 1);
        return;
      }

      // INFO: Hat das Laden der Belege Erfolg, so soll nochmal ein Count ausgeführt werden
      this.belegService.countBelege(inhaberId, filter).pipe(
        take(1),
        catchError(error => {
          this.logger.warn('could not count belege for utility-widget', error);
          return of(undefined);
        }),
      ).subscribe(countedBelege => {

        if (!countedBelege) {
          this.logger.warn('countedBelege is undefined:', countedBelege);
        } else {
          if (!belege) {
            this.logger.warn('belege is', belege, '. Use default value "[]".');
            belege = [];
          }
          // INFO: Anschließend das UtilityWidgetData aktualisieren
          this.updateUtilityWidgetData(inhaberId, belege, countedBelege.count, pageIndex, pageSize);
        }

      });
    });
  }

  /**
   * Aktualisiert das UtilityWidgetData.
   *
   * @param inhaberId ID des aktuellen Inhabers
   * @param pageIndex Der aktuelle PageIndex
   * @param belege Die Belege zu dem Inhaber
   * @param countedBelege Die Anzahl aller Belege zu dem Inhaber
   * @private
   */
  private updateUtilityWidgetData(
    inhaberId: string,
    belege: BelegDTO[],
    countedBelege: number,
    pageIndex: number,
    pageSize: number,
  ): void {
    const inhaber = this.navigationService.inhaberList.find(inhaber => inhaber.id === inhaberId)!;

    const newUtilityWidgetData: UtilityWidgetData = {
      inhaber: inhaber,
      belege,
      countedBelege,
      pageIndex,
      pageSize,
    };

    const currentUtilityWidgetData = this.utilityWidgetData$.getValue();

    if (currentUtilityWidgetData) {
      // INFO: Wenn bereits Daten im UtilityWidgetData vorhanden sind, diese aktualisieren

      let utilityWidgetDataCpy = [...currentUtilityWidgetData];
      const foundDataIndex = utilityWidgetDataCpy.findIndex(data => data.inhaber.id === inhaber.id);

      if (foundDataIndex > -1) {
        utilityWidgetDataCpy = utilityWidgetDataCpy.filter(data => data.inhaber.id !== inhaber.id);
        utilityWidgetDataCpy.splice(foundDataIndex, 0, newUtilityWidgetData);
      } else {
        utilityWidgetDataCpy.push(newUtilityWidgetData);
      }

      // INFO: Sortieren, damit immer die gleiche Reihenfolge gewährleistet ist
      utilityWidgetDataCpy = utilityWidgetDataCpy?.sort((a, b) => {
        return a.inhaber.nummer - b.inhaber.nummer;
      });

      this.utilityWidgetData$.next(utilityWidgetDataCpy);
    } else {
      // INFO: Wenn keine Daten im UtilityWidgetData vorhanden sind, diese erstmalig hinzufügen

      this.utilityWidgetData$.next([newUtilityWidgetData]);
    }
  }

  /**
   * Aktualisiert das übergebene UtilityWidgetHeaderData mit den neuen Daten aus dem UtilityWidgetData.
   *
   * @param widgetHeaderData Das zu aktualisierende UtilityWidgetHeaderData
   * @param utilityWidgetData Das aktuelle UtilityWidgetData
   * @private
   */
  private updateUtilityWidgetHeaderData(widgetHeaderData: WidgetHeaderData, utilityWidgetData: UtilityWidgetData) {
    const currentWidgetInhaberData = widgetHeaderData.widgetInhaberData;

    const newWidgetInhaberData: WidgetInhaberData = {
      inhaber: utilityWidgetData.inhaber,
      todoExists: utilityWidgetData.belege.length > 0,
    };

    if (currentWidgetInhaberData) {
      // INFO: Wenn bereits Daten im UtilityWidgetHeaderData vorhanden sind, diese aktualisieren

      const widgetInhaberDataCpy = [...currentWidgetInhaberData];

      const foundInhaberDataIndex = widgetInhaberDataCpy.findIndex(inhaberData => {
        return inhaberData.inhaber.id === utilityWidgetData.inhaber.id;
      });

      if (foundInhaberDataIndex && foundInhaberDataIndex > -1) {
        widgetInhaberDataCpy.splice(foundInhaberDataIndex, 1, newWidgetInhaberData);
      } else {
        widgetInhaberDataCpy.push(newWidgetInhaberData);
      }

      widgetHeaderData.widgetInhaberData = widgetInhaberDataCpy;
    } else {
      // INFO: Wenn keine Daten im UtilityWidgetHeaderData vorhanden sind, diese erstmalig hinzufügen

      widgetHeaderData.widgetInhaberData = [newWidgetInhaberData];
    }
  }
}
